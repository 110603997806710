
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SearchBar',
  methods: {
    shuffleSearchBarHints() {
      setInterval(() => {
        this.searchBarHintIdx++
        this.searchBarHintIdx %= this.searchBarHints.length
        this.searchBarHint = `Cari ${this.searchBarHints[this.searchBarHintIdx]}`
      }, 3000)
    },
    handleSubmit() {
      this.$router.push({ query: { keyword: this.keyword } });
      setTimeout(() => {
        this.$emit('loadData');
      }, 10);
    },
    handleInput(e: Event) {
      this.keyword = (e.target as HTMLInputElement).value
    },
  },
  data() {
    return {
      searchBarHints: [
        'Rumah',
        'Ruko',
        'Tanah',
        'Kos-kosan',
      ],
      searchBarHintIdx: 0,
      searchBarHint: 'Cari Rumah',
      keyword: '',
    }
  },
  mounted() {
    this.shuffleSearchBarHints()
  },
})
