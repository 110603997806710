import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = {
  key: 0,
  class: "text-sm text-ellipsis line-clamp-2"
}
const _hoisted_4 = {
  key: 1,
  class: "text-sm text-ellipsis line-clamp-2"
}
const _hoisted_5 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    class: "w-full shadow-md rounded-lg hover:bg-gray-100 active:bg-gray-300 duration-300"
  }, [
    _createElementVNode("img", {
      src: [_ctx.property.foto[0]],
      class: "w-full aspect-square rounded-t-lg"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.property.hiddenKeyword != null)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.property.hiddenKeyword), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.property.judul), 1)),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.parseIdr(_ctx.property.harga)), 1)
    ])
  ]))
}