
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaginationFooter',
  props: {
    page: Number,
    maxPage: Number,
  },
  data() {
    return {
      pageNum: 0,
      maxPageNum: 0,
      showPage: [] as Array<number>,
    };
  },
  mounted() {
    this.pageNum = parseInt(this.page?.toString() || '1');
    this.maxPageNum = parseInt(this.maxPage?.toString() || '1');
    this.getRange();
  },
  methods: {
    getRange() {
      if (this.pageNum-1 >= 1) this.showPage.push(this.pageNum-1);
      this.showPage.push(this.pageNum);
      if (this.pageNum+1 < this.maxPageNum) this.showPage.push(this.pageNum+1);
      while(this.showPage.length < 3 && this.showPage[this.showPage.length-1] < this.maxPageNum) {
        this.showPage.push(this.showPage[this.showPage.length-1] + 1);
      }
      this.showPage.reverse()
      while(this.showPage.length < 3 && this.showPage[this.showPage.length-1] > 1) {
        this.showPage.push(this.showPage[this.showPage.length-1] - 1);
      }
      this.showPage.reverse()
    },
    changePage(newPage: number) {
      this.$emit('changePage', newPage);
    },
  },
})
