import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2 flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        type: "file",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePhotoChange && _ctx.handlePhotoChange(...args)))
      }, null, 32),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlePhotoUpload && _ctx.handlePhotoUpload(...args))),
        class: "px-2 rounded-lg bg-gray-100 hover:bg-gray-200 active:bg-gray-400 duration-300"
      }, _toDisplayString(_ctx.isSaved ? 'Saved' : 'Save'), 1)
    ])
  ]))
}