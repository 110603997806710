import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "search-bar" }
const _hoisted_2 = { class: "flex items-stretch" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "border-2 border-l-0 rounded-r-lg" }
const _hoisted_5 = ["click"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: [_ctx.searchBarHint],
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keyword) = $event)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["enter"])),
        class: "w-full h-12 p-4 border-2 border-r-0 rounded-l-lg"
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.keyword]
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          click: _ctx.handleSubmit,
          class: "flex items-center h-full px-4 bg-red-500 rounded-r-md hover:bg-red-600 active:bg-red-800 duration-300"
        }, [
          _createVNode(_component_fa_icon, {
            icon: "fa-solid fa-magnifying-glass",
            class: "text-white"
          })
        ], 8, _hoisted_5)
      ])
    ])
  ]))
}