
import { defineComponent } from 'vue'

import { useToast } from 'vue-toastification'

import post from '../http/post'

import PhotoInput from '../components/PhotoInput.vue'

export default defineComponent({
  name: 'LoginPage',
  components: {
    PhotoInput,
  },
  methods: {
    handleAddPhoto() {
      this.foto.push('')
    },
    handleRemovePhoto() {
      this.foto.pop()
    },
    handlePhotoSaved(idx: number, url: string) {
      this.foto[idx] = url
    },
    async handleSubmit() {
      this.foto.filter((fotoUrl) => (fotoUrl && fotoUrl.length > 0))
      try {
        const resp = await post('/property', {
          kategori: this.kategori,
          status: this.status,
          harga: this.harga,
          hargaPerM2: this.hargaPerM2,
          hargaPerTahun: this.hargaPerTahun,
          tipe: this.tipe,
          judul: this.judul,
          deskripsi: this.deskripsi,
          provinsi: this.provinsi,
          kota: this.kota,
          luasTanah: this.luasTanah,
          luasBangunan: this.luasBangunan,
          jumlahLantai: this.jumlahLantai,
          kamarTidur: this.kamarTidur,
          kamarMandi: this.kamarMandi,
          kamarTidurPembantu: this.kamarTidurPembantu,
          kamarMandiPembantu: this.kamarMandiPembantu,
          foto: this.foto,
          hiddenKeyword: this.hiddenKeyword,
        })
        if (resp.status >= 400) throw new Error(resp.data)
        this.$router.push('/admin')
      }
      catch (err: unknown) {
        if (typeof err === 'string') this.toast.error(err)
        else if (err instanceof Error) this.toast.error(err.message)
      }
    },
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      kategori: '',
      status: '',
      harga: 0,
      hargaPerM2: 0,
      hargaPerTahun: 0,
      tipe: '',
      judul: '',
      deskripsi: '',
      provinsi: '',
      kota: '',
      luasTanah: 0,
      luasBangunan: 0,
      jumlahLantai: 0,
      kamarTidur: 0,
      kamarMandi: 0,
      kamarTidurPembantu: 0,
      kamarMandiPembantu: 0,
      foto: [''],
      hiddenKeyword: '',
    }
  },
})
