
import { defineComponent } from 'vue'

export default defineComponent({
  name: "FilterForm",
  data() {
    return {
      query: {
        minHarga: null,
        maxHarga: null,
        minHargaPerM2: null,
        maxHargaPerM2: null,
        minHargaPerTahun: null,
        maxHargaPerTahun: null,
        luasBangunan: null,
        luasTanah: null,
        kamarTidur: null,
        kamarMandi: null,
        kamarTidurPembantu: null,
        kamarMandiPembantu: null,
      }
    }
  },
  methods: {
    async handleSubmit() {
      this.$emit('closeFilter');
      this.$router.push({ 
        query: {
          ...this.$route.query,
          ...this.query
        } 
      });
      setTimeout(() => {
        this.$emit('loadData');
      }, 10);
    },
    onQueryChanged() {
      // This function will be run whenever `query` changes.
      // You can put any code you want here.
      // this.$store.dispatch('module/updateFilterQuery', this.query);
    },
  },
  watch: {
    query: {
      handler: 'onQueryChanged',
      deep: true,
    }
  }
})
