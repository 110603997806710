
import { defineComponent } from "vue"
import BottomSheet from './BottomSheet.vue'
import FilterForm from "./FilterForm.vue"

export default defineComponent({
  name: 'FilterSection',
  components: {
    BottomSheet,
    FilterForm,
  },
  data() {
    return {
      isFilterOpen: false,
    }
  },
  methods: {
    handleOpenFilter() {
      this.isFilterOpen = true;
    },
    handleCloseFilter() {
      this.isFilterOpen = false;
    },
    loadData() {
      console.log('here')
      this.$emit('loadData');
    },
  },
})
