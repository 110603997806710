
import { defineComponent } from 'vue'

import PropertiesGridItem from './PropertiesGridItem.vue'

export default defineComponent({
  name: 'PropertiesGrid',
  components: {
    PropertiesGridItem,
  },
  props: {
    properties: Array,
  },
})
