
import { defineComponent } from 'vue'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'PhotoInput',
  props: {
    photoId: Number,
  },
  methods: {
    handlePhotoChange(e: Event) {
      if ((e.target as HTMLInputElement).files && (e.target as HTMLInputElement).files?.length) {
        const files = (e.target as HTMLInputElement).files
        this.photoFile = files![0]
      }
    },
    handlePhotoUpload() {
      const reader = new FileReader()
      reader.readAsDataURL(this.photoFile as Blob)
      reader.onload = () => {
        const image = (reader.result as string).split(',')[1]
        const form = new FormData()
        form.append('image',image)
        fetch(process.env.VUE_APP_IMGBB_URL, {
          method: 'POST',
          headers: { },
          body: form
        })
        .then(resp => resp.json())
        .then(json => {
          if (!json.success) throw new Error('Gagal mengunggah foto profil')
          this.toast.success('Photo saved')
          this.$emit('photoSaved', this.photoId, json.data.url)
        })
        .catch((err: unknown) => {
          if (typeof err === 'string') this.toast.error(err)
          else if (err instanceof Error) this.toast.error(err.message)
        })
      }
      reader.onerror = () => {
        this.toast.error('Photo upload failed')
      }
    },
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      photoFile: null as unknown,
      photoUrl: '',
    }
  }
})
