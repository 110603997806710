
import { defineComponent } from 'vue'

import PropertiesListItem from './PropertiesListItem.vue'

export default defineComponent({
  name: 'PropertiesList',
  components: {
    PropertiesListItem,
  },
  props: {
    properties: Array,
  },
})
