
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BottomSheet',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      startY: 0,
      endY: 0,
      dragging: false,
    }
  },
  methods: {
    close() {
      this.$emit('update:isOpen', false);  // Emit 'update' event
    },
    handleStart(event: MouseEvent | TouchEvent) {
      this.startY = 'touches' in event ? event.touches[0].clientY : event.clientY;
      this.dragging = true;
    },
    handleMove(event: MouseEvent | TouchEvent) {
      if (!this.dragging) return;
      this.endY = 'touches' in event ? event.touches[0].clientY : event.clientY;
      if (this.endY - this.startY > 100) {
        this.close();
      }
    },
    handleEnd() {
      this.dragging = false;
    },
  },
  created() {
    window.addEventListener('mouseup', this.handleEnd);
    window.addEventListener('touchend', this.handleEnd);
  },
  beforeUnmount() {
    window.removeEventListener('mouseup', this.handleEnd);
    window.removeEventListener('touchend', this.handleEnd);
  }
})
