
import { defineComponent } from 'vue'

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import PropertiesGrid from './PropertiesGrid.vue'
import PropertiesList from './PropertiesList.vue'
import Pagination from './Pagination.vue'

export default defineComponent({
  name: 'PropertiesSection',
  components: {
    PulseLoader,
    PropertiesGrid,
    PropertiesList,
    Pagination,
  },
  props: {
    properties: Array,
    page: Number,
    maxPage: Number,
  },
  methods: {
    changeViewMode() {
      this.viewModeIdx = (this.viewModeIdx+1) % this.viewModes.length
      this.viewMode = this.viewModes[this.viewModeIdx]
    },
    changePage(newPage: number) {
      this.$emit('changePage', newPage);
    },
  },
  data() {
    return {
      viewModes: ['grid','list'],
      viewModeIdx: 0,
      viewMode: 'grid',
      scope: 'Semua',
      color: '#ef4444'
    }
  },
})
