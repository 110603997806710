<script>
  export default {
    name: 'DocumentTitle',
    props: [
      'title'
    ],
    watch: {
      title: {
        immediate: true,
        handler() {
          document.title = this.title;
        }
      }
    },
  }
</script>