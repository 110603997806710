
import { defineComponent } from 'vue'

import { useToast } from 'vue-toastification'

import post from '../http/post'
import DocumentTitle from '../components/DocumentTitle.vue'

export default defineComponent({
  name: 'LoginPage',
  components: {
    DocumentTitle,
  },
  methods: {
    async handleLogin() {
      try {
        const resp = await post('/auth/login', {
          email: this.email,
          password: this.password,
        })
        if (resp.status >= 400) throw new Error(resp.data)
        localStorage.setItem('accessToken', resp.data.accessToken)
        localStorage.setItem('refreshToken', resp.data.refreshToken)
        this.$router.push('/admin')
      }
      catch (err: unknown) {
        if (typeof err === 'string') this.toast.error(err)
        else if (err instanceof Error) this.toast.error(err.message)
      }
    },
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      email: '',
      password: '',
      isPasswordHidden: true,
    }
  },
})
