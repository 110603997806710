import { createRouter, createWebHistory } from 'vue-router'

import DashboardPage from '../pages/Dashboard.vue'
import PropertyDetailPage from '../pages/PropertyDetail.vue'
import LoginPage from '../pages/Login.vue'
import NewPropertyPage from '../pages/NewProperty.vue'
import AdminDashboardPage from '../pages/AdminDashboard.vue'
import AdminPropertyDetailPage from '../pages/AdminPropertyDetail.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: DashboardPage },
    { path: '/property/:id', component: PropertyDetailPage },
    { path: '/login', component: LoginPage },
    { path: '/admin', component: AdminDashboardPage },
    { path: '/admin/property/:id', component: AdminPropertyDetailPage },
    { path: '/admin/new-property', component: NewPropertyPage },
  ],
})

export default router