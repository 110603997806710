import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PropertiesGridItem = _resolveComponent("PropertiesGridItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (property) => {
      return (_openBlock(), _createBlock(_component_PropertiesGridItem, {
        key: property.id,
        property: property
      }, null, 8, ["property"]))
    }), 128))
  ]))
}