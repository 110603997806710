import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PropertiesListItem = _resolveComponent("PropertiesListItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (property) => {
      return (_openBlock(), _createBlock(_component_PropertiesListItem, {
        key: property.id,
        property: property
      }, null, 8, ["property"]))
    }), 128))
  ]))
}